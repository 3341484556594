import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Colors } from '../lib/colors';
import logo from '../assets/home/logo@2x.png';

export const Footer = () => {
  const styles = useStyles();
  return (
    <Box style={{ backgroundColor: '#F2F1F0' }}>
      <Box className={styles.container}>
        <Box className={styles.innerContainer}>
          <Box className={styles.menu}>
            <Box>
              <Typography className={styles.menuText}>NOSOTROS</Typography>
              <Typography className={styles.menuText}>OFERTA EDUCATIVA</Typography>
              <Typography className={styles.menuText}>SECUNDARIA</Typography>
              <Typography className={styles.menuText}>PREPARATORIA</Typography>
              <Typography className={styles.menuText}>ADMISIONES Y CONTACTO</Typography>
              <Typography className={styles.menuText}>TABLERO DE NOTICIAS</Typography>
            </Box>
            <Box className={styles.externalLinks}>
              <Typography>Área de alumnos</Typography>
              <Typography>Área de maestros</Typography>
            </Box>
          </Box>
          <img className={styles.logo} alt="logo IDEA" src={logo}></img>
          <Box>
            <Typography className={styles.contact}>Calle Juan M. Zambada #5474</Typography>
            <Typography className={styles.contact}>Col. Las Flores 80104 Culiacán,</Typography>
            <Typography className={styles.contact}>Sinaloa, México</Typography>
            <Typography className={styles.contact}>Tel. (667) 7 15 89 16</Typography>
            <Typography className={styles.contact}>info@institutoidea.edu.mx</Typography>
            <Typography className={styles.contactHidden}>placeholder</Typography>
            <Typography variant="overline" className={styles.designedby}>
              <a className={styles.designedby} href="https://delaherranmurillo.com">
                DISEÑO: DE LA HERRÁN + MURILLO
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '64px 64px 0px 0px',
    background: 'black',
    color: 'white',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 64,
    },
  },
  innerContainer: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    maxWidth: '1400px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    marginTop: '2rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      width: '10rem',
      marginBottom: '4rem',
      aspectRatio: 'auto',
    },
    width: '10rem',
    aspectRatio: 'auto',
  },
  menu: {
    visibility: 'hidden',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    textAlign: 'left',
  },
  menuText: {
    fontFamily: 'Ariana Pro',
    fontSize: '16px',
    fontWeight: 300,
  },
  contact: {
    fontSize: '16px',
    fontFamily: 'Kohinoor Devanagari',
    fontWeight: 300,
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  contactHidden: {
    fontSize: '16px',
    fontFamily: 'Kohinoor Devanagari',
    fontWeight: 300,
    textAlign: 'right',
    visibility: 'hidden',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  designedby: {
    fontSize: '13px',
    color: 'white',
    textDecoration: 'solid',
    fontFamily: 'Kohinoor Devanagari',
    fontWeight: 300,
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  externalLinks: {
    color: Colors.orange,
  },
}));
