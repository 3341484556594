import {
  Box,
  unstable_createMuiStrictModeTheme as createMuiTheme,
  Hidden,
  makeStyles,
  MuiThemeProvider,
  Typography,
  Button,
  Container,
} from '@material-ui/core';
import React from 'react';
import { Colors } from '../lib/colors';
import { Solicitud } from './Solicitud';
import logo from '../assets/home/logo@2x.png';
import tree from '../assets/home/ArbolLibroNuve.png';
import redSymbol from '../assets/home/redSymbol.svg';
import whatsAppLogo from '../assets/WhatsAppLogo2.svg';

export const Home = () => {
  const styles = useStyles();
  const theme1 = useTheme1();
  return (
    <Box style={{ backgroundColor: '#F2F1F0' }}>
      <MuiThemeProvider theme={theme1}>
        <Box className={styles.whatsAppFloat}>
          <a href="https://wa.me/526671748482">
            <img alt="whatsapp: 6671748482" src={whatsAppLogo} />
          </a>
        </Box>
        <Box style={{ backgroundColor: Colors.orange }}>
          <Container style={{ backgroundColor: Colors.orange, position: 'relative', overflow: 'hidden' }}>
            <Box display="flex" flexDirection="column" width="100%">
              <Box className={styles.mainTextContainer}>
                <Box display="flex" flexDirection="column" position="relative">
                  <Typography className={styles.headerText}>EN IDEA,</Typography>
                  <Typography className={styles.headerTextBoldInner}>NUESTRO</Typography>
                  <Typography className={styles.headerTextBoldInner}>VALOR</Typography>
                  <Typography className={styles.headerTextBold}>ERES TÚ.</Typography>
                  <svg height="20" width="20" className={styles.redDot} xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="10" fill={Colors.red} />
                  </svg>
                  <svg height="20" width="20" className={styles.yellowDot2} xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="10" fill={Colors.yellow} />
                  </svg>
                  <svg height="20" width="20" className={styles.turquoiseDot} xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="10" fill={Colors.turquoise} />
                  </svg>
                </Box>
              </Box>
            </Box>
            <img className={styles.tree} alt="arbol, libro" src={tree}></img>
            <img className={styles.redSymbol} alt="logo symbol" src={redSymbol}></img>
            <svg height="20" width="20" className={styles.yellowDot} xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="10" fill={Colors.yellow} />
            </svg>
          </Container>
        </Box>
        <Box style={{ backgroundColor: Colors.orange }}>
          <Container>
            <Hidden smUp>
              <img className={styles.logo} alt="logo IDEA" src={logo}></img>
              <Box style={{ paddingBottom: 64 }}>
                <Typography className={styles.descriptionMain}>
                  Somos una institución educativa especializada en los jóvenes en desarrollo. Ofrecemos los niveles
                  educativos de Secundaria y Preparatoria.
                </Typography>
                <Typography className={styles.descriptionSecondary}>
                  Nos caracteriza la educación personalizada y un ambiente de seguridad, confianza y aceptación.
                </Typography>
              </Box>
            </Hidden>
            <Hidden xsDown>
              <Box className={styles.sideBySide}>
                <Box display="flex" flex={1} alignItems="center" justifyContent="center">
                  <img className={styles.logo} alt="logo IDEA" src={logo}></img>
                </Box>
                <Box display="flex" flex={1} flexDirection="column" alignItems="center" justifyContent="space-between">
                  <Typography className={styles.descriptionMain}>
                    Somos una institución educativa especializada en los jóvenes en desarrollo. Ofrecemos los niveles
                    educativos de Secundaria y Preparatoria.
                  </Typography>
                  <Typography className={styles.descriptionSecondary}>
                    Nos caracteriza la educación personalizada y un ambiente de seguridad, confianza y aceptación.
                  </Typography>
                </Box>
              </Box>
            </Hidden>
            <Box className={styles.ctaContainer}>
              <Box className={styles.ctaInnerContainer}>
                <Typography className={styles.ctaTitle}>¡INSCRÍBETE AHORA!</Typography>
              </Box>
            </Box>
          </Container>
        </Box>
        <Solicitud></Solicitud>
        <Box style={{ backgroundColor: Colors.red, paddingTop: '64px', paddingBottom: '64px' }}>
          <Container style={{ margin: '64px, 0px, 64px, 0px' }}>
            <Typography className={styles.redBlock}>
              También puedes llamarnos para solicitar informes o agendar una visita a nuestras instalaciones
            </Typography>
            <Button color="secondary" href={'tel:6677158916'} variant="contained" className={styles.masInfoButton}>
              LLAMAR AHORA
            </Button>
          </Container>
        </Box>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7244.6808182129935!2d-107.4424676!3d24.7837946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86bcd05d1a729a55%3A0x3a6af4e0d2872eb5!2sSecundaria%20y%20Preparatoria%20IDEA!5e0!3m2!1sen!2snl!4v1611263300104!5m2!1sen!2snl"
          className={styles.map}
          aria-hidden="false"
        ></iframe>
        {/* <FixedWidthBlock>
                        <Box className={styles.sections}>
                            <Box className={styles.section}>
                                <Typography color="secondary" className={styles.titleSection}>SECUNDARIA</Typography>
                                <Typography className={styles.descriptionSection}>Durante los tres grados de Secundaria, en IDEA se le enseña al alumno a ser autónomo en su aprendizaje y se le guía en el desarrollo de sus habilidades.</Typography>
                                <Button variant="contained" color="secondary" className={styles.masInfoButton}>MÁS INFORMACIÓN</Button>
                            </Box>
                            <Box className={styles.section}>
                                <Typography color="primary" className={styles.titleSection}>PREPARATORIA</Typography>
                                <Typography className={styles.descriptionSection}>En Prepa IDEA nuestro enfoque está enformar personas gradualmente más autónomas, capaces de vincularse con su comunidad y con la excelencia académica imprescindible para su éxito en el nivel universitario.</Typography>
                                <Button variant="contained" color="primary" className={styles.masInfoButton}>MÁS INFORMACIÓN</Button>
                            </Box>
                        </Box>
                </FixedWidthBlock> */}
      </MuiThemeProvider>
    </Box>
  );
};

const useTheme1 = () =>
  createMuiTheme({
    palette: {
      primary: { main: Colors.turquoise },
      secondary: { main: Colors.yellow },
      error: { main: Colors.red },
    },
  });

const useStyles = makeStyles((theme) => ({
  description: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.orange,
    color: 'white',
  },
  tree: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    [theme.breakpoints.down('xs')]: {
      right: -40,
    },
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      width: '60%',
      padding: '16px 0px 16px 0px',
      aspectRatio: 'auto',
    },
    width: '280px',
  },
  redSymbol: {
    position: 'absolute',
    opacity: 0.8,
    top: 70,
    left: 0,
    width: 320,
    [theme.breakpoints.down(830)]: {
      display: 'none',
    },
  },
  mainTextContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down(830)]: {
      alignItems: 'flex-start',
    },
  },
  descriptionMain: {
    textAlign: 'left',
    color: 'white',
    fontSize: '28px',
    fontFamily: 'Kohinoor Devanagari',
    fontWeight: 'bold',
    paddingTop: 32,
    paddingBottom: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      paddingTop: 32,
      paddingBottom: 16,
    },
  },
  descriptionSecondary: {
    paddingRight: '64px',
    textAlign: 'left',
    color: 'white',
    fontSize: '28px',
    fontFamily: 'Kohinoor Devanagari',
    fontWeight: 300,
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
      padding: '16px 0px 0px 0px',
    },
  },
  container: {
    maxWidth: '1400px',
  },
  sideBySide: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 64,
  },
  headerText: {
    textAlign: 'left',
    color: 'white',
    fontSize: '78px',
    fontFamily: 'Ariana Pro',
    lineHeight: 1,
    paddingTop: 64,
    [theme.breakpoints.down('xs')]: {
      fontSize: 42,
      paddingTop: 64,
      paddingLeft: 16,
    },
  },
  headerTextBold: {
    textAlign: 'left',
    color: 'white',
    fontSize: '78px',
    fontFamily: 'Ariana Pro',
    fontWeight: 900,
    lineHeight: 1,
    paddingBottom: 64,
    [theme.breakpoints.down('xs')]: {
      fontSize: 42,
      paddingBottom: 240,
      paddingLeft: 16,
    },
  },
  headerTextBoldInner: {
    textAlign: 'left',
    color: 'white',
    fontSize: '78px',
    fontFamily: 'Ariana Pro',
    fontWeight: 900,
    lineHeight: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: 42,
      paddingLeft: 16,
    },
  },
  dudas: {
    textAlign: 'right',
    color: 'black',
    fontSize: '16px',
    alignSelf: 'flex-end',
    fontFamily: 'Ariana Pro',
    fontWeight: 300,
    lineHeight: 1,
    [theme.breakpoints.up('sm')]: {
      paddingTop: '64px',
    },
  },
  sections: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-around',
    },
  },
  section: {
    paddingBottom: '32px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '35%',
    },
  },
  titleSection: {
    fontFamily: 'Ariana Pro',
    fontSize: 46,
    fontWeight: 900,
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
    },
  },
  descriptionSection: {
    fontFamily: 'Kohinoor Devanagari',
    fontWeight: 300,
    padding: '8px',
    paddingBottom: '16px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
  },
  masInfoButton: {
    color: 'white',
    fontFamily: 'Kohinoor Devanagari',
    fontWeight: 'bold',
    marginTop: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: '22px',
    },
  },
  redBlock: {
    fontFamily: 'Ariana Pro',
    color: 'white',
    fontSize: '42px',
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: '26px',
    },
  },
  cadaAlumno: {
    color: 'white',
  },
  ctaContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  ctaInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.turquoise,
    width: '70%',
    borderRadius: '32px 32px 0px 0px',
    alignItems: 'center',
    padding: '32px 32px 0px 32px',
  },
  ctaTitle: {
    fontFamily: 'Ariana Pro',
    fontSize: 58,
    color: 'white',
    fontWeight: 900,
    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
    },
  },
  map: {
    width: '100%',
    height: 600,
    borderWidth: 0,
  },
  yellowDot: {
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    top: 70,
    left: 220,
  },
  redDot: {
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    top: 25,
    left: 90,
  },
  yellowDot2: {
    position: 'absolute',
    top: 120,
    left: 440,
    [theme.breakpoints.down('xs')]: {
      top: 80,
      left: 270,
    },
  },
  turquoiseDot: {
    opacity: 0.8,
    position: 'absolute',
    top: 400,
    left: 90,
    [theme.breakpoints.down('xs')]: {
      top: 260,
      left: 60,
    },
  },
  whatsAppFloat: {
    position: 'fixed',
    zIndex: 2,
    width: 80,
    height: 80,
    left: '16px',
    bottom: '16px',
    [theme.breakpoints.down('xs')]: {
      left: '8px',
      bottom: '8px',
      width: 64,
      height: 64,
    },
    userSelect: 'none',
  },
}));
