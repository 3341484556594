import React from 'react';
import './App.css';
import { AlertBar } from './components/AlertBar';
import { Footer } from './components/Footer';
import { Home } from './components/Home';
import { MenuBar } from './components/MenuBar';

function App() {
  return (
    <div className="App">
      {/* <TestimonyGroup></TestimonyGroup> */}
      {/* <Actualizacion></Actualizacion> */}
      <AlertBar></AlertBar>
      <MenuBar></MenuBar>
      <Home></Home>
      <Footer></Footer>
    </div>
  );
}

export default App;
