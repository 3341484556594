import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Colors } from '../lib/colors';

export const AlertBar = () => {
  const styles = useStyles();
  return (
    <Box className={styles.container}>
      <Box className={styles.alertbar}>
        <Box className={styles.textBlock}>
          <Typography className={styles.shortText} variant="overline">
            INSCRIPCIONES ABIERTAS 2021-2022
          </Typography>
          <Typography className={styles.longText} variant="body2">
            INSCRIPCIONES ABIERTAS PARA CICLO ESCOLAR 2021-2022
          </Typography>
        </Box>
        <Box className={styles.linkBlock}>
          <Typography variant="body2" className={styles.linkText}>
            Área alumnos
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.turquoise,
  },
  alertbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '1400px',
    backgroundColor: Colors.turquoise,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  textBlock: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  bar: {
    justifyContent: 'space-between',
  },
  linkBlock: {
    display: 'flex',
    color: 'white',
    marginLeft: '1rem',
    marginRight: '1rem',
    padding: '.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  linkText: {
    fontFamily: 'Ariana Pro',
    fontSize: '19px',
    fontWeight: 700,
  },
  shortText: {
    fontFamily: 'Ariana Pro',
    fontSize: '12px',
    fontWeight: 400,
    display: 'none',
    color: 'white',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  longText: {
    fontFamily: 'Ariana Pro',
    fontSize: '17px',
    fontWeight: 400,
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    padding: '.5rem',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
