import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Input,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useState } from 'react';
import { Colors } from '../lib/colors';

export const Solicitud = () => {
  const styles = useStyles();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [nivel, setNivel] = useState<string>('');
  const [relation, setRelation] = useState<string>('');
  const [grado, setGrado] = useState<number | string>('');
  const [contactVia, setContactVia] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [triedToSubmit, setTriedToSubmit] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<boolean>(false);
  const [snackbarTitle, setSnackbarTitle] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarColor, setSnackbarColor] = useState<string>('');

  const formIsValid = (): boolean => {
    if (name && email && phone && nivel && grado && relation && grado && contactVia) return true;
    else return false;
  };

  const submitEnabled = (): boolean => {
    return (formIsValid() || !triedToSubmit) && !sending;
  };

  const hideSnackbar = () => {
    setSnackbarTitle('');
    setSnackbarMessage('');
    setSnackbar(false);
  };

  const clearForm = () => {
    setName('');
    setEmail('');
    setPhone('');
    setNivel('');
    setRelation('');
    setGrado('');
    setContactVia('');
    setMessage('');
  };

  const showSnackbar = (type: 'error' | 'invalid' | 'ok') => {
    switch (type) {
      case 'invalid':
        setSnackbarTitle('COMPLETA EL FORMULARIO');
        setSnackbarMessage('Es necesario llenar todos los campos.');
        setSnackbarColor(Colors.yellow);
        setSnackbar(true);
        break;
      case 'error':
        setSnackbarTitle('ERROR AL ENVIAR EL FORMULARIO');
        setSnackbarMessage('Verifica tu coneccion a internet e intenta más tarde.');
        setSnackbarColor(Colors.red);
        setSnackbar(true);
        break;
      case 'ok':
        setSnackbarTitle('¡HEMOS RECIBIDO TU SOLICITUD!');
        setSnackbarMessage('Te contactaremos muy pronto.');
        setSnackbarColor(Colors.turquoise);
        setSnackbar(true);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    setTriedToSubmit(true);
    if (!formIsValid()) {
      console.log('Error enviando solicitud');
      showSnackbar('invalid');
      return;
    }
    setSending(true);
    axios
      .post('https://us-west4-instituto-idea-cms.cloudfunctions.net/send-lead', {
        nombre: name,
        email,
        telefono: phone,
        relacionConElAlumno: relation,
        nivel,
        grado,
        responderMediante: contactVia,
        mensaje: message,
      })
      .then(() => {
        console.log('Solicitud enviada');
        clearForm();
        showSnackbar('ok');
      })
      .catch(() => {
        console.log('Error enviando solicitud');
        showSnackbar('error');
      })
      .then(() => {
        setSending(false);
      });
  };

  return (
    <React.Fragment>
      <Box style={{ backgroundColor: 'white' }}>
        <Container>
          <Box className={styles.ctaContainer}>
            <Box className={styles.ctaInnerContainer}>
              <Typography className={styles.cta}>
                ¡Estamos recibiendo solicitudes para el ciclo escolar 2021 - 2022!
              </Typography>
            </Box>
          </Box>
          <Typography className={styles.formTitle}>
            Solicita la información que necesites, agenda una cita o inicia ahora mismo el proceso de inscripción.
          </Typography>
        </Container>
      </Box>
      <Box style={{ backgroundColor: 'white' }}>
        <Container>
          <Box className={styles.informationRequestForm}>
            <form onSubmit={handleSubmit}>
              <InputLabel className={styles.label} htmlFor="nombre">
                Nombre
              </InputLabel>
              <Input
                error={triedToSubmit && name === ''}
                className={styles.input}
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="name"
              />
              <InputLabel className={styles.label} htmlFor="email">
                Correo electrónico
              </InputLabel>
              <Input
                error={triedToSubmit && email === ''}
                className={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
              />
              <InputLabel className={styles.label} htmlFor="telefono">
                Teléfono móvil o fijo
              </InputLabel>
              <Input
                error={triedToSubmit && phone === ''}
                className={styles.input}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                id="telefono"
              />
              <InputLabel className={styles.label} htmlFor="relacion">
                Relación con el alumno
              </InputLabel>
              <Input
                error={triedToSubmit && relation === ''}
                className={styles.input}
                value={relation}
                onChange={(e) => setRelation(e.target.value)}
                id="relacion"
              />
              <InputLabel className={styles.label}>Nivel</InputLabel>
              <Select
                className={styles.input}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={nivel}
                error={triedToSubmit && nivel === ''}
                onChange={(e) => setNivel(e.target.value as string)}
              >
                <MenuItem className={styles.inputNoMargin} value={'secundaria'}>
                  Secundaria
                </MenuItem>
                <MenuItem className={styles.inputNoMargin} value={'preparatoria'}>
                  Preparatoria
                </MenuItem>
              </Select>
              <InputLabel className={styles.label} id="grado-label">
                Grado
              </InputLabel>
              <Select
                className={styles.input}
                labelId="grado-label"
                id="grado"
                value={grado}
                error={triedToSubmit && !grado}
                onChange={(e) => {
                  setGrado(e.target.value as number);
                }}
              >
                <MenuItem className={styles.inputNoMargin} value={1}>
                  1er Grado
                </MenuItem>
                <MenuItem className={styles.inputNoMargin} value={2}>
                  2do Grado
                </MenuItem>
                <MenuItem className={styles.inputNoMargin} value={3}>
                  3er Grado
                </MenuItem>
              </Select>
              <InputLabel className={styles.label} id="contact-via-label">
                Prefiero recibir información vía
              </InputLabel>
              <Select
                className={styles.input}
                labelId="contact-via-label"
                id="contact-via"
                value={contactVia}
                error={triedToSubmit && contactVia === ''}
                onChange={(e) => {
                  setContactVia(e.target.value as string);
                }}
              >
                <MenuItem className={styles.inputNoMargin} value={'phone'}>
                  Teléfono
                </MenuItem>
                <MenuItem className={styles.inputNoMargin} value={'email'}>
                  Correo electrónico
                </MenuItem>
              </Select>
              <InputLabel className={styles.label}>Escríbenos tu mensaje</InputLabel>
              <TextField
                className={styles.input}
                InputProps={{
                  className: styles.input,
                }}
                id="mensaje"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value as string);
                }}
                multiline
                rows={8}
                rowsMax={16}
                variant="outlined"
              />
              <Button
                variant="contained"
                disabled={!submitEnabled()}
                color="primary"
                onClick={handleSubmit}
                className={styles.sendButton}
              >
                ENVIAR
              </Button>
              {sending && <LinearProgress />}
              <Snackbar open={snackbar} autoHideDuration={10000} onClose={hideSnackbar}>
                <Card raised={true} style={{ backgroundColor: snackbarColor }}>
                  <CardContent>
                    <Typography className={styles.cardTitle} gutterBottom>
                      {snackbarTitle}
                    </Typography>
                    <Typography className={styles.cardMessage}>{snackbarMessage}</Typography>
                  </CardContent>
                </Card>
              </Snackbar>
            </form>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  ctaContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  ctaInnerContainer: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.turquoise,
    borderRadius: '0px 0px 32px 32px',
    alignItems: 'center',
    padding: '16px 32px 32px 32px',
  },
  cta: {
    width: '70%',
    fontFamily: 'Kohinoor Devanagari',
    fontSize: 28,
    color: 'white',
    fontWeight: 300,
    [theme.breakpoints.down('xs')]: {
      fontSize: 19,
      width: '90%',
    },
  },
  formTitle: {
    color: 'black',
    fontFamily: 'Kohinoor Devanagari',
    fontSize: 28,
    fontWeight: 300,
    paddingTop: 64,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 32,
      fontSize: 19,
    },
  },
  label: {
    fontFamily: 'Ariana Pro',
    textAlign: 'left',
    color: 'black',
    fontSize: 28,
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  input: {
    fontFamily: 'Kohinoor Devanagari',
    fontSize: 24,
    fontWeight: 300,
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    marginBottom: 32,
  },
  inputNoMargin: {
    fontFamily: 'Kohinoor Devanagari',
    fontSize: 24,
    fontWeight: 300,
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  sendButton: {
    color: 'white',
    fontFamily: 'Kohinoor Devanagari',
    fontWeight: 'bold',
    marginBottom: 32,
    [theme.breakpoints.up('sm')]: {
      fontSize: '22px',
      marginBottom: 64,
    },
  },
  informationRequestForm: {
    paddingRight: 64,
    paddingLeft: 64,
    paddingTop: 64,

    [theme.breakpoints.down('xs')]: {
      padding: 32,
    },
  },
  cardTitle: {
    color: 'white',
    fontFamily: 'Kohinoor Devanagari',
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      fontSize: '22px',
    },
    marginBottom: 32,
  },
  cardMessage: {
    color: 'white',
    fontFamily: 'Kohinoor Devanagari',
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
    marginBottom: 16,
  },
  cardButton: {
    fontFamily: 'Ariana Pro',
    color: 'white',
    fontWeight: 700,
    borderColor: 'white',
    borderWidth: '5px',
    borderStyle: 'solid',
  },
}));
