import { AppBar, makeStyles, Toolbar } from '@material-ui/core';
// import MenuIcon from '@material-ui/icons/Menu';
// import PhoneEnabledRoundedIcon from '@material-ui/icons/PhoneEnabledRounded';
import React from 'react';
import { Colors } from '../lib/colors';
import logo from '../assets/home/LogoBarraSup@2x.png';

export const MenuBar = () => {
  const styles = useStyles();
  return (
    <AppBar position="sticky" className={styles.root}>
      <Toolbar className={styles.toolbar}>
        {/* <IconButton className={styles.phoneButton} aria-label="menu">
                <PhoneEnabledRoundedIcon />
            </IconButton> */}
        <img alt="logo-idea" className={styles.logo} src={logo}></img>
        {/* <Box className={styles.navigation}>
              <Button className={styles.toolbarButton}>NOSOTROS</Button>
              <Button className={styles.toolbarButton}>OFERTA EDUCATIVA</Button>
              <Button className={styles.toolbarButton}>ADMISIONES Y CONTACTO</Button>
              <Button className={styles.toolbarButton}>TABLERO DE NOTICIAS</Button>
            </Box> */}
        {/* <Box>
              <IconButton className={styles.menuButton} aria-label="menu">
                  <MenuIcon />
              </IconButton>
            </Box> */}
      </Toolbar>
    </AppBar>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    justifyContent: 'center',
  },
  menuButton: {
    color: Colors.orange,
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      // display: "flex",
    },
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '1400px',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      // justifyContent: "space-around",
      // padding: "16px",
    },
  },
  navigation: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logo: {
    justifySelf: 'center',
    height: '3rem',
    [theme.breakpoints.down('sm')]: {
      height: '2rem',
      marginLeft: 0,
    },
  },
  phoneButton: {
    color: Colors.gray,
    justifySelf: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbarButton: {
    fontFamily: 'Ariana Pro',
    fontSize: '17px',
    fontWeight: 300,
  },
}));
